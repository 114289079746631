export default {
  head_title_pt: 'Rotesma - Agradecimento',
  head_title_en: 'Rotesma - Agradecimento',
  head_title_es: 'Rotesma - Gracias',
  title_pt: 'Mensagem enviada com sucesso.',
  title_en: 'Mensagem enviada com sucesso.',
  title_es: 'Mensaje enviado correctamente.',
  description_pt: 'Agradecemos o envio da sua mensagem. Em breve nossa equipe entrará em contato.',
  description_en: 'Agradecemos o envio da sua mensagem. Em breve nossa equipe entrará em contato.',
  description_es: 'Gracias por enviar su mensaje. Nuestro equipo se pondrá en contacto en breve.',
  link_pt: 'Voltar',
  link_en: 'Voltar',
  link_es: 'Volver'
}
